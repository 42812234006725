import {GetEndpoint} from '../../src/library/ajax/getEndpoint';
import {KeySystem_WithoutOrderItems, SystemHeaderForm} from '../types/emksHeadPositionController';
import {PostEndpoint} from '../../src/library/ajax/postEndpoint';
import {HeadPosition} from '../types/headPosition/headPosition.';
import {KeySystem, ProductConfig} from '../types/emksKeySystemController';

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html

/**
 * Creates a head position based on a key system
 */
export const createHeadPositionForReorder = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		systemCode: string;
		headPositionCode: string;
		rootB2BUnit: string;
	},
	void,
	string
>({
	url: ({ hybrisStore, user, language, systemCode, headPositionCode, rootB2BUnit }) => {
		const headPosition = headPositionCode ? `&headPositionCode=${headPositionCode}` : '';
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/?systemCode=${systemCode}${headPosition}&lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}`
	},
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Creates a head position based on a key system
 */
export const createHeadPositionForNewKeySystemByFileUpload = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		fileReference: string;
		rootB2BUnit: string;
	},
	void,
	string
>({
	url: ({ hybrisStore, user, language, fileReference, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/?fileReference=${fileReference}&lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({ headerOptions: { authToken: token }, timeout: 600000 }),
});

/**
 * Creates a head position based on a key system for reorder
 */
export const createHeadPositionForReorderByFileUpload = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		systemCode: string;
		headPositionCode: string;
		fileReference: string;
		rootB2BUnit: string;
	},
	void,
	string
>({
	url: ({ hybrisStore, user, language, systemCode, headPositionCode, fileReference, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/reorder?systemCode=${systemCode}&headPositionCode=${headPositionCode}&fileReference=${fileReference}&lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Creates a head position based on a key system for extension
 */
export const createHeadPositionForExtensionByFileUpload = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		systemCode: string;
		headPositionCode: string;
		fileReference: string;
		rootB2BUnit: string;
	},
	void,
	string
>({
	url: ({ hybrisStore, user, language, systemCode, headPositionCode, fileReference, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/extension?systemCode=${systemCode}&headPositionCode=${headPositionCode}&fileReference=${fileReference}&lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns the head position
 */
export const getHeadPosition = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		headPosition: string;
		rootB2BUnit: string;
	},
	HeadPosition
>({
	url: ({ hybrisStore, user, language, headPosition, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/${headPosition}?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns content of key system for head position
 */
export const getKeySystemForHeadPosition = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		headPosition: string;
		rootB2BUnit: string;
	},
	KeySystem_WithoutOrderItems
>({
	url: ({ hybrisStore, user, language, headPosition, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/${headPosition}/keysystem?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: false,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns content of key system with master data for head position
 */
export const getKeySystemForHeadPositionWithMasterData = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		headPosition: string;
		rootB2BUnit: string;
	},
	SystemHeaderForm
>({
	url: ({ hybrisStore, user, language, headPosition, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/${headPosition}/keysystem?masterData=true&lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns content of key system with master data for head position
 */
export const getKeySystemForHeadPositionWithOrderItems = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		headPosition: string;
		cartId: string;
		rootB2BUnit: string;
	},
	KeySystem
>({
	url: ({ hybrisStore, user, language, cartId, headPosition, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartId}/headpositions/${headPosition}/keysystem?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns content of key system with master data for head position
 */
export const getOrderItems = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		language: string;
		headPosition: string;
		cartId: string;
		rootB2BUnit: string;
		requestedItemType: string;
		currentPage?: number;
	},
	Record<string, any>,
	KeySystem
>({
	url: ({ hybrisStore, user, language, cartId, headPosition, rootB2BUnit, requestedItemType, currentPage }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartId}/headpositions/${headPosition}/keysystem/entries?lang=${language}&rootB2BUnit=${rootB2BUnit}&requestedItemType=${requestedItemType}&page=${currentPage}`,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Update content of key system with master data for head position
 */
export const updateKeySystemForHeadPosition = PostEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		language: string;
		user: string;
		headPosition: string;
		rootB2BUnit: string;
	},
	KeySystem_WithoutOrderItems,
	void
>({
	url: ({ hybrisStore, user, headPosition, rootB2BUnit, language }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/${headPosition}/keysystem?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getHeadPosition.clearCache();
			getKeySystemForHeadPosition.clearCache();
			getKeySystemForHeadPositionWithMasterData.clearCache();
		},
	}),
});

/**
 * Return entry product configuration .
 */
export const getEntryConfiguration = GetEndpoint.create<
	{
		language: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		headPositionCode: string;
		materialId: string;
		productCode: string;
		overrideDefaultWithSpecialFunctions?: boolean;
	},
	ProductConfig
>({
	url: ({language, user, hybrisStore, rootB2BUnit, headPositionCode, materialId, productCode, overrideDefaultWithSpecialFunctions}) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/${headPositionCode}/config_status?lang=${language}&rootB2BUnit=${rootB2BUnit}&orderItemId=${materialId}&productCode=${productCode}`
		+ (overrideDefaultWithSpecialFunctions ? `&overrideDefaultWithSpecialFunctions=${overrideDefaultWithSpecialFunctions}` : ''),
	cacheEnabled: false,
	options: ({token}) => ({headerOptions: {authToken: token}}),
});
